<template>
  <v-container class="page">
    <div class="page-header mb-5 text-h6">
      <div>Audio Test</div>
    </div>
    <div class="page-content" v-if="isStart">
      <div v-for="(message, i) in messages" :key="i" class="my-4">
        {{ message }}
      </div>
    </div>
    <div class="page-content" v-else>
      <v-btn color="blue darken-2" depressed dark @click="startTesting"
        >Start Testing</v-btn
      >
    </div>
    <div class="page-footer"></div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    isStart: false,
    messages: [],
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    startTesting() {
      this.isStart = true;
      this.audio = new Audio(
        require("@/mockups/ABC/book-1/assets/audio/1-e.wav")
      );
      this.audio.load();
      this.audio.onloadstart = () => {
        this.messages.push("Starting to load audio");
      };
      this.audio.onerror = () => {
        this.messages.push("Error loading audio");
      };
      this.audio.oncanplaythrough = () => {
        this.messages.push("Audio loaded");
        this.audio.play();
      };
      this.audio.onplay = () => {
        this.messages.push("Starting to play audio");
      };
      this.audio.onplaying = () => {
        this.messages.push("Audio is playing");
      };
      this.audio.onended = () => {
        this.messages.push("Audio ended");
      };
    },
  },
};
</script>

<style scoped>
.page-content,
.page-footer,
.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}
</style>